import React from "react";
import Helmet from "react-helmet";

interface LayoutProps {
  title: string;
  description: string;
  children?: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({
  title,
  description,
  children,
}) => (
  <>
    <Helmet title={title}>
      <html lang="en" />
      <link rel="shortcut icon" href="/favicon.png" />
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={title} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
    </Helmet>
    <main className="main w-screen">{children}</main>
  </>
);
