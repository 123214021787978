import React from "react";
import { Backhoe } from "../components/icons/Backhoe";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Envelope } from "../components/icons/Envelope";
import { Phone } from "../components/icons/Phone";
import { Compass } from "../components/icons/Compass";

export interface HeroProps {
  id: string;
}
export const Hero: React.FC<HeroProps> = ({ id }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Logo.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <section
      id={id}
      className="min-h-screen bg-gradient-to-tr from-gray-100 to-gray-300 grid grid-cols-1 lg:grid-cols-2 overflow-x-hidden"
    >
      <div className="container flex items-center justify-center">
        <div className="space-y-6 lg:text-left lg:m-12 text-gray-800">
          <div className="flex items-center justify-center text-primary-800">
            <Backhoe className="h-24 w-24" />
          </div>
          <h2 className="text-xl lg:text-2xl 2xl:text-4xl font-semibold">
            Hier entsteht die Homepage für das Vermessungsbüro Peitz.
          </h2>
          <p className="text-lg lg:text-xl 2xl:text-2xl font-semibold">
            Sie erreichen uns unter
          </p>
          <div className="text-lg lg:text-xl 2xl:text-2xl font-semibold">
            <a
              href={"mailto:info@peitz-vermessung.de"}
              className="my-6 flex items-center justify-start text-primary-800 hover:text-primary-600 duration-300"
            >
              <Envelope className="h-10 w-10" />
              <p className="pl-6">info@peitz-vermessung.de</p>
            </a>
            <a
              href={"tel:040591623"}
              className="my-6 flex items-center justify-start text-primary-800 hover:text-primary-600 duration-300"
            >
              <Phone className="h-10 w-10" />
              <p className="pl-6">040 / 591623</p>
            </a>
            <a
              href={
                "https://www.google.com/maps/place/Klosterallee+106D,+20144+Hamburg"
              }
              className="my-6 flex items-center justify-start text-primary-800 hover:text-primary-600 duration-300"
            >
              <Compass className="h-10 w-10" />
              <p className="pl-6">Klosterallee 106d, 20144 Hamburg</p>
            </a>
          </div>
          {/* <p>
            <Link
              to="#kontakt"
              className="text-white uppercase font-semibold bg-primary-800 hover:bg-primary-600 px-10 py-3 rounded-xl duration-300 2xl:text-lg 2xl:py-5"
            >
              Kontakt
            </Link>
          </p> */}
        </div>
      </div>
      <div className="container flex items-center justify-center">
        <div className="square w-96 mx-3 my-12 lg:w-full lg:h-1/2 rounded-xl relative group">
          <div className="absolute -inset-5 rounded-xl bg-gradient-to-tr from-primary-800 to-primary-500 transform rotate-3 group-hover:-rotate-1 duration-500 z-0" />

          <div className="bg-gray-100 absolute w-full h-full rounded-xl">
            <GatsbyImage
              alt={"Hand, die Person geborgen hält"}
              image={data.file.childImageSharp.gatsbyImageData}
              style={{
                width: "100%",
                height: "100%",
              }}
              className="rounded-xl"
              imgStyle={{
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
