import React from "react";
// import { Contact } from "../sections/Contact";
// import { Feature } from "../sections/Feature";
// import { Footer } from "../sections/Footer";
import { Hero } from "../sections/Hero";
import { Layout } from "../components/Layout";
// import { Navbar } from "../components/Navbar";
// import { Services } from "../sections/Services";
// import { Team } from "../sections/Team";
// import { CTA } from "../sections/CTA";

const Home = () => {
  return (
    <Layout
      title="Peitz Vermessung - Ihre Ansprechstelle für Vermessungsangelegenheiten"
      description={`Hier entsteht die Homepage für das Vermessungsbüro Peitz.`}
    >
      {/* <Navbar /> */}
      <Hero id="hero" />
      {/* <Services id="leistungen" /> */}
      {/* <CTA id="" /> */}
      {/* <Feature id="ueber-uns" /> */}
      {/* <Team id="team" /> */}
      {/* <Contact id="kontakt" /> */}
      {/* <Footer /> */}
    </Layout>
  );
};

export default Home;
